:root {
  --primary: #111112;
  --accent: #d8e4fb;
  --secondary: #d8e4fb;
  --success: #058c4b;
  --table-background-color: #f1f1f1;

  --light-accent: #d8e4fb;
  --dark-accent: #252930;

  /* Antd */
  --antd-btn-disabled-color: rgba(0, 0, 0, 0.25);
  --antd-btn-disabled-background-color: rgb(246, 246, 246);

  /* Sidebar Customisation */
  --sidebar: #111112;
  --sidebar-width: 256px;
  --sidebar-width-sm: 48px;
  --menu-item-height: 40px;
  --menu-item-font-size: 14px;
  --layout-header-height: 64px;

  /* Main Page Sizes */
  --layout-header-height: 64px;
  --page-header-search-bar-with-margin: 72px;
  --layout-page-bottom-padding: 16px;
  --pagination-component-with-margin: 48px;
  --antd-table-calc-wrapper-height: calc(
    var(--layout-header-height) + var(--page-header-search-bar-with-margin) + var(--layout-page-bottom-padding)
  );
  --antd-table-calc-height: calc(var(--antd-table-calc-wrapper-height) + var(--pagination-component-with-margin));

  /* Workbench Cohorts Component Sizes - Add Data Step*/
  --workbench-steps-header-height: var(--layout-header-height);
  --workbench-add-data-title-with-margin: 68px;
  --workbench-tabs-list-with-margin: 62px;
  --workbench-search-bar-with-gap: 56px;
  --workbench-step-buttons-with-padding: 64px;
  --layout-content-bottom-margin: 1rem;

  --workbench-steps-content-height: calc(
    var(--layout-header-height) + var(--workbench-steps-header-height) + var(--workbench-step-buttons-with-padding)
  );

  --workbench-cohorts-table-wrapper-height: calc(
    var(--layout-header-height) + var(--workbench-steps-header-height) + var(--workbench-add-data-title-with-margin) +
      var(--workbench-tabs-list-with-margin) + var(--workbench-search-bar-with-gap) +
      var(--workbench-step-buttons-with-padding) + var(--layout-content-bottom-margin)
  );
  --workbench-cohorts-table-height: calc(
    var(--workbench-cohorts-table-wrapper-height) + var(--pagination-component-with-margin)
  );

  /* Workbench Users Component Sizes - Add Users Step*/
  --workbench-add-users-title-with-margin: 73px;
  --workbench-users-table-wrapper-height: calc(
    var(--layout-header-height) + var(--workbench-steps-header-height) + var(--workbench-add-data-title-with-margin) +
      var(--workbench-search-bar-with-gap) + var(--workbench-step-buttons-with-padding) +
      var(--layout-content-bottom-margin)
  );
  --workbench-users-table-height: calc(
    var(--workbench-users-table-wrapper-height) + var(--pagination-component-with-margin)
  );

  --workbench-review-alert-height: 54px;
  --workbench-review-container-height: calc(
    var(--layout-header-height) + var(--workbench-steps-header-height) + var(--workbench-review-alert-height) +
      var(--workbench-step-buttons-with-padding)
  );

  --workbench-overview-header-height: 176px;
  --workbench-overview-tab-padding: 32px;
  --workbench-overview-tab-offset-margins: 18px;
}
