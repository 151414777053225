@import url(./vars.css);

/* Force wrapping div to take up full page height */
#__next {
  min-height: 100vh;
}

html,
body,
#__next {
  padding: 0;
  margin: 0;
  display: flex;
  flex-grow: 1;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body {
  height: 100vh;
}

.router-loading-wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

main {
  margin: 12px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.text-on-dark {
  color: white;
  font-weight: normal !important;
}

.text-on-light {
  color: black;
}

.ant-layout-content {
  overflow-y: auto;
}

.ant-layout-sider-trigger {
  background-color: var(--dark-accent) !important;
}

.ant-btn:disabled,
.ant-btn-primary.ant-btn-dangerous:disabled {
  color: var(--antd-btn-disabled-color);
  background-color: var(--antd-btn-disabled-background-color);
}

.empty-page {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
}

.ant-notification-notice-message {
  font-weight: bold;
}

.ant-popover-arrow {
  display: none;
}

.ant-popover-inner-content .ant-menu-vertical {
  border-right: none;
}

.ant-table-thead .ant-table-cell {
  background-color: var(--table-background-color) !important;
}

.ant-typography-ellipsis {
  &::after {
    /* Prevents the displaying of html tooltips in Safari browser */
    content: "";
    display: block;
  }
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
